import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import zhCn from 'element-plus/es/locale/lang/zh-cn'
import ElementPlus, { ElMessage } from 'element-plus'
import 'element-plus/dist/index.css'

import './style/index.scss'

// 引入ele icons
import * as EleIcons from '@element-plus/icons-vue'

const app = createApp(App)
for (const name in EleIcons) {
  app.component(name, EleIcons[name])
}

app.use(store).use(router).use(ElementPlus, { locale: zhCn }).mount('#app')

// beforeEach - 前置守卫, 路由跳转前触发方法
// to - 下一个路由、from - 当前路由、 next - 执行跳转，必须得有
router.beforeEach((to, from, next) => {
  if (to.path === '/password') {
    if (!store.state.userInfo.token) {
      next()
    } else {
      ElMessage.error('账号已登录,请前往个人中心修改密码!')
      next({ name: 'Home' })
    }
  } else if (to.path === '/user') {
    if (store.state.userInfo.user) {
      next()
    } else {
      ElMessage.error('用户未登录,请登录后查看个人中心!')
      next({ name: 'Home' })
    }
  } else {
    next()
  }
})

// afterEach - 后置守卫，路由跳转后触发方法
// 页面跳转后回到顶部
router.afterEach((to, from, next) => {
  document.getElementsByClassName('scroll-content')[0].scrollTo(0, 0)
})
