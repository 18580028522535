import { createStore } from 'vuex'

const setLocalUserInfo = (state) => {
  const { userInfo } = state
  const userInfoString = JSON.stringify(userInfo)
  localStorage.userInfo = userInfoString
}

const getLocalUserInfo = () => {
  try {
    return JSON.parse(localStorage.userInfo)
  } catch (e) {
    return {}
  }
}

export default createStore({
  state: {
    userInfo: getLocalUserInfo()
  },
  getters: {
  },
  mutations: {
    changeUserInfo (state, payload) {
      const { userInfo } = payload
      state.userInfo = userInfo
      setLocalUserInfo(state)
    }
  },
  actions: {
  },
  modules: {
  }
})
