<template>
  <el-scrollbar wrap-class="scroll-content" ref="scroll" id="my-scrollbar" class="my-scrollbar">
    <router-view/>
  </el-scrollbar>
</template>

<style lang="scss">
.my-scrollbar {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
