import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/Home.vue')
  }, {
    path: '/register',
    name: 'Register',
    component: () => import('../views/register/Register.vue')
  }, {
    path: '/password',
    name: 'Password',
    component: () => import('../views/register/Password.vue')
  }, {
    path: '/vips',
    name: 'Vips',
    component: () => import('../views/vips/Vips.vue')
  }, {
    path: '/list',
    name: 'List',
    component: () => import('../views/list/List.vue')
  }, {
    path: '/label',
    name: 'Label',
    params: { name: '' },
    component: () => import('../views/label/Label.vue')
  }, {
    path: '/demo',
    name: 'Demo',
    component: () => import('../views/list/demo.vue')
  }, {
    path: '/article',
    name: 'Article',
    component: () => import('../views/article/Article.vue')
  }, {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/User.vue')
  }, {
    path: '/tags',
    name: 'Tags',
    component: () => import('../views/tags/Tags.vue')
  }, {
    path: '/taglist',
    name: 'TagList',
    component: () => import('../views/tags/TagList.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/register/404.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
